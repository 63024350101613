import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AuthenticatedPage from '../../Components/AuthenticatedPage';
import { updatePassword } from '../../api';
import { connect } from 'react-redux';

const AdminProfile = ({ admin }) => {
  const [email] = useState(admin ? admin.email : '');
  const [formData, setFormData] = useState({});
  const [feedback, setFeedback] = useState('');
  const [disabled, setDisabled] = useState(false);

  const onChange = (event) => {
    const { name, value } = event.target;
    const newFormData = { ...formData };
    newFormData[name] = value;
    setFormData(newFormData);
    setFeedback('');
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const missingPassword = !password || password.length === 0;
    const missingNewPassword1 = !newPassword1 || newPassword1.length === 0;
    const missingNewPassword2 = !newPassword2 || newPassword2.length === 0;
    if (missingPassword || missingNewPassword1 || missingNewPassword2) {
      setFeedback('All fields are required');
      return;
    }
    const newPasswordsMatch = newPassword1 === newPassword2;
    if (!newPasswordsMatch) {
      setFeedback('New passwords must match');
      return;
    }
    setFeedback('');
    setDisabled(true);
    updatePassword(admin.id, password, newPassword1)
      .then((json) => {
        if (json.id) {
          setFeedback('Password updated');
          setFormData({});
          setDisabled(false);
        }
      })
      .catch((error) => {
        setFeedback(error.message);
        setDisabled(false);
      });
  };

  const { password, newPassword1, newPassword2 } = formData;
  return (
    <AuthenticatedPage>
      <div className="page-title">Profile</div>
      <form onSubmit={onSubmit}>
        <div className="form_input_section">
          <label className="form_input_section__label" htmlFor="email">
            Email (Cannot be changed)
          </label>
          <input type="text" name="email" placeholder="" value={email} disabled={true} />
        </div>
        <div className="form_input_section">
          <label className="form_input_section__label" htmlFor="password">
            Current Password
          </label>
          <input
            type="password"
            name="password"
            placeholder="Current Password"
            value={password}
            onChange={onChange}
          />
        </div>
        <div className="form_input_section">
          <label className="form_input_section__label" htmlFor="newPassword1">
            New Password
          </label>
          <input
            type="password"
            name="newPassword1"
            placeholder="New Password"
            value={newPassword1}
            onChange={onChange}
          />
        </div>
        <div className="form_input_section">
          <label className="form_input_section__label" htmlFor="newPassword2">
            Confirm New Password
          </label>
          <input
            type="password"
            name="newPassword2"
            placeholder="Confirm New Password"
            value={newPassword2}
            onChange={onChange}
          />
        </div>
        <div className="form_input_section">
          <input type="submit" disabled={disabled} />
        </div>
      </form>
      <p>{feedback}</p>
    </AuthenticatedPage>
  );
};

AdminProfile.propTypes = {
  admin: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { admin } = state;
  return { admin };
};

export default connect(mapStateToProps)(AdminProfile);
